import {
  Alert,
  Button,
  Input,
  message,
  Modal,
  Popconfirm,
  Progress,
  Radio,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../component/Header";
import { EditFilled } from "@ant-design/icons";
import config from "../../config/config";
import { date } from "../../utility";

const dataSource = {
  key: "1",
  name: "Mike",
  email: "odunilade@gmail.com",
  date: "2nd aug, 2022",
  status: "active",
};

function OrderTransaction() {
  const [formData1, setFormData1] = useState({
    model_type: "Payment",
  });
  const [progress, setProgress] = useState(false);
  const [progressLength, setProgressLength] = useState(0);
  const [editing, setEditing] = useState(null);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    // SuspendRestuarant();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [user_id, setUser_id] = useState("");
  const [formdata, setFormdata] = useState({
    entityID: editing?._id,
    model_type: "Payment",
    duration: "24hrs",
    note: "",
  });
  const [value1, setValue1] = useState("24hrs");
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setFormdata({ ...formdata, duration: e.target.value });
  };
  console.log(formdata);

  const [susLoading, setSusLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [BusinessData, setBusinessData] = useState([]);

  console.log(user_id);
  //   const SuspendRestuarant = async () => {
  //     setProgress(true);
  //     setProgressLength(0);
  //     setFormdata({ ...formdata, entityID: user_id });
  //     try {
  //       setProgressLength(75);
  //       const res = (
  //         await config.post(`admin/suspension/suspend`, {
  //           ...formdata,
  //           entityID: user_id,
  //         })
  //       ).data;
  //       SelectedPayment();
  //       setProgressLength(100);
  //       console.log("suspend", res);
  //     } catch (error) {
  //       console.log("suspend", error);
  //       setError(error?.response?.data?.message);
  //     }
  //     setProgressLength(100);
  //     setIsModalOpen(false);
  //     setProgress(false);
  //   };
  const confirm = (e) => {
    console.log(e);

    // UnSuspendRestuarant();
  };
  const cancel = (e) => {
    console.log(e);
  };

  //   const UnSuspendRestuarant = async () => {
  //     setFormData1({ ...formData1, model_type: "Business" });
  //     setSusLoading(true);

  //     try {
  //       const res = (
  //         await config.post(`/admin/suspension/${user_id}/unsuspend`, formData1)
  //       ).data;

  //       console.log("unsuspend", res);
  //       message.success("Unsuspended");
  //       SelectedPayment();
  //       setSusLoading(false);
  //     } catch (error) {
  //       console.log("suspend", error);
  //       setError(error?.response?.data?.message);
  //       message.error(error);
  //     }
  //   };
  const onClose = (e) => {
    console.log(e, "I was closed.");
  };
  const [value2, setValue2] = useState(1);
  const onChange2 = (e) => {
    console.log("radio checked", e.target.value);
    setValue2(e.target.value);
  };
  const [dataSource, setDataSource] = useState([...BusinessData]);
  const [value, setValue] = useState("");

  const SelectedPayment = async () => {
    try {
      const res = (
        await config.get(
          `/admin/payment-management/getPaymentDetails/${user_id}`
        )
      ).data;
      console.log("sel", res);
      setEditing(res.data);
    } catch (error) {
      console.log("Error", error);
      setError(error?.response?.data?.message);
    }
  };
  //   const ApproveBusiness = async () => {
  //     setLoading(true);
  //     try {
  //       const res = (
  //         await config.patch(`admin/business-management/verify/${user_id}`)
  //       ).data;
  //       console.log("response", res);
  //       SelectedBusinesss();
  //       setLoading(false);
  //     } catch (error) {
  //       setError(error?.response?.data?.message);
  //       console.log("approve error", error);
  //     }
  //   };
  const Payment = async () => {
    try {
      const res = (
        await config.get(`/admin/payment-management/list?page=1&limit=1000000`)
      ).data;
      const arr = res.data;
      for (let i = 0; i < arr.length; i++) {
        arr[i].createdAt = date(arr[i].createdAt);
      }
      setBusinessData(arr);
      console.log(res.data);
    } catch (error) {
      console.log("Error", error);
      setError(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    SelectedPayment();
  }, [user_id]);
  const [rider, setRider] = useState({
    orderID: user_id,
    status: "",
  });
  const [order, setOrder] = useState({
    orderID: user_id,
    status: "",
  });
  useEffect(() => {
    setRider({
      ...rider,
      orderID: editing?.orderId?._id,
      status: editing?.orderId?.riderStatus,
    });
    setOrder({
      ...order,
      orderID: editing?.orderId?._id,
      status: editing?.orderId?.status,
    });
  }, [editing?.orderId]);
  console.log("status", order, rider);

  const updateOrder = async () => {
    setLoading(true);
    try {
      const res = (
        await config.post("/admin/order-management/update-order-status", order)
      ).data;
      const res1 = (
        await config.post("/admin/order-management/update-rider-status", rider)
      ).data;
      console.log("response", res1.data);
      console.log("response", res.data);
      alert("Success");

      Payment();
      setEditing(null);
    } catch (error) {
      console.log("error updating orderStatus", error.response);
      alert(error.response.data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    Payment();
  }, [value === ""]);
  const flattenData = BusinessData.map((item) => ({
    id: item._id,
    name: item.orderId.customerDetails.name,
    amount: item.amount,
    date: item.createdAt,
    paymentStatus: item?.orderId?.paymentStatus,
    rider: item?.orderId?.riderStatus,
    status: item?.orderId?.status,
    orderNumber: item?.orderId?.orderNumber,
    address: item.orderId?.customerDetails?.mainAddress?.description,
  }));
  console.log(flattenData, "array");

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Order no.",
      dataIndex: "orderNumber",
      key: "orderNumber",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "",
    },

    {
      title: "Amount $",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Date",
      dataIndex: `date`,
      key: "date",
    },
    {
      title: "Rider",
      dataIndex: "rider",
      key: "rider",
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "status",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, param) => {
        return (
          <Controls>
            <button
              onClick={() => {
                setUser_id(param?.id);
              }}
            >
              <EditFilled />
            </button>
          </Controls>
        );
      },
    },
  ];
  return (
    <div>
      <div>
        <Header
          title="Payment"
          description="Added, edit and delete Business accounts"
          right
          text={
            <Typography style={{ fontSize: 24, fontWeight: 600 }}>
              <span>Total</span> : $20000
            </Typography>
          }
        />
      </div>
      <Input
        placeholder="Search "
        size="middle"
        style={{ marginBottom: 20, height: 50, borderRadius: 15 }}
        value={value}
        onChange={(e) => {
          const currValue = e.target.value;
          setValue(currValue);
          const filteredData = BusinessData.filter((entry) =>
            entry.name.toLowerCase().includes(currValue.toLowerCase())
          );
          setBusinessData(filteredData);

          console.log("data", BusinessData);
        }}
      />
      <MyTable
        columns={columns}
        showHeader
        className="myTable"
        dataSource={[...flattenData]}
      />
      {error && (
        <Alert message={error} type="warning" closable onClose={onClose} />
      )}
      <Modal
        title="Suspension Duration"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value="24hrs">24hrs</Radio>
              <Radio value="1m">1minite</Radio>
              <Radio value="1w">1 week</Radio>
              <Radio value="permanent">permanent</Radio>
            </Space>
          </Radio.Group>
          {progress && (
            <Progress
              style={{ marginRight: "20px" }}
              type="circle"
              percent={progressLength}
              format={() => "Suspend"}
            />
          )}
        </div>
        <TextArea
          placeholder="Note"
          required
          style={{ background: "#242424", marginTop: 15 }}
          value={formdata.note}
          onChange={(e) => {
            setFormdata({ ...formdata, note: e.target.value });
          }}
        ></TextArea>
      </Modal>
      <CustomModal
        // centered
        onCancel={() => setEditing(null)}
        visible={Boolean(editing)}
        width={800}
        footer={null}
      >
        <ModalTitle>Order Details</ModalTitle>

        <ModalContent>
          <DetailsCon>
            <span className="label">Customer name</span>
            <span className="value">
              {editing?.orderId?.customerDetails?.name}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Customer Address</span>
            <span className="value">
              {editing?.orderId?.customerDetails?.mainAddress?.description}
            </span>
          </DetailsCon>

          <DetailsCon>
            <span className="label">isInfleuncerOrder</span>
            <span className="value">
              {editing?.orderId?.isInfleuncerOrder ? "Yes" : "No"}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Business Name</span>
            <span className="value">{editing?.orderId?.businessId?.name}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Business Address</span>
            <span className="value">
              {editing?.orderId?.businessId?.address}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Order Number</span>
            <span className="value">{editing?.orderId?.orderNumber}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Order Quantity</span>
            <span className="value">
              {editing?.orderId?.orderItems?.length}{" "}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Distance </span>
            <span className="value">{editing?.orderId?.distance} Ml </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Appoximate Time of Delivery</span>
            <span className="value">{editing?.orderId?.eta} </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Shipping Fee</span>
            <span className="value">
              {editing?.orderId?.shippingFee} {editing?.orderId?.currencyCode}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Tax Fee</span>
            <span className="value">
              {editing?.orderId?.vat} {editing?.orderId?.currencyCode}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Total</span>
            <span className="value">
              {editing?.orderId?.totalAmount} {editing?.orderId?.currencyCode}
            </span>
          </DetailsCon>
        </ModalContent>
        <ModalTitle>Payment details</ModalTitle>

        <ModalContent>
          <DetailsCon>
            <span className="label">Payment Gatway</span>
            <span className="value">{editing?.payment_gateway}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Transaction Refrence</span>
            <span className="value">{editing?.tx_ref}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Transaction ID</span>
            <span className="value">{editing?.trx_id}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Paymen Date</span>
            <span className="value">{date(editing?.createdAt)}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Paymeny Currency</span>
            <span className="value">{editing?.currency}</span>
          </DetailsCon>
        </ModalContent>
        <ModalTitle>Action</ModalTitle>

        <ModalContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <div style={{ color: "#fff" }}>
            <Typography
              style={{
                color: "#fff",
                fontWeight: 600,
                fontSize: 18,
                marginBottom: 20,
              }}
            >
              Rider Status
            </Typography>
            <Radio.Group
              onChange={(e) => {
                setRider({ ...rider, status: e.target.value });
              }}
              value={rider.status}
            >
              <Space direction="vertical">
                <Radio style={{ color: "#fff" }} value="processing">
                  Pending
                </Radio>
                <Radio style={{ color: "#fff" }} value="accepted">
                  Accepted
                </Radio>
                <Radio style={{ color: "#fff" }} value="shipped">
                  Shipped
                </Radio>
                <Radio style={{ color: "#fff" }} value="delivered">
                  Delivered
                </Radio>
                <Radio style={{ color: "#fff" }} value="rejected">
                  Rejected
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          <div style={{ color: "#fff" }}>
            <Typography
              style={{
                color: "#fff",
                fontWeight: 600,
                fontSize: 18,
                marginBottom: 20,
              }}
            >
              Order Management
            </Typography>
            <Radio.Group
              onChange={(e) => {
                setOrder({ ...order, status: e.target.value });
              }}
              value={order.status}
            >
              <Space direction="vertical">
                <Radio style={{ color: "#fff" }} value="processing">
                  Processing
                </Radio>
                <Radio style={{ color: "#fff" }} value="accepted">
                  Accepted
                </Radio>
                <Radio style={{ color: "#fff" }} value="shipped">
                  Shipped
                </Radio>
                <Radio style={{ color: "#fff" }} value="delivered">
                  Delivered
                </Radio>
                <Radio style={{ color: "#fff" }} value="rejected">
                  Rejected
                </Radio>
              </Space>
            </Radio.Group>
          </div>
        </ModalContent>

        <ModalContent>
          <Button
            loading={loading}
            style={{ width: "100%" }}
            onClick={() => {
              updateOrder();
            }}
          >
            Update{" "}
          </Button>
        </ModalContent>
      </CustomModal>
    </div>
  );
}

export default OrderTransaction;

const MyTable = styled(Table)`
  & .ant-table {
    background: #fff1;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 40px #0006;
  }

  & .ant-table-thead {
    background: #fff4;
  }
  & .ant-table-cell {
    color: #fff;
    background: none;
    border-bottom: 1px solid #fff2;
  }
  & .ant-table-tbody > tr.ant-table-row:hover > td,
  & .ant-table-tbody > tr > td.ant-table-cell-row-hove {
    background: #fff3;
  }

  & .ant-pagination-item-link {
    background: var(--tertiary);
    color: #fff;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  & button {
    background: none;
    color: #fff;
    font-size: 16px;
  }
`;

const CustomModal = styled(Modal)`
  & .ant-modal-content {
    background: var(--tertiary);
    border-radius: 20px;
    box-shadow: 0 0 40px #0006;
  }
`;

const ModalTitle = styled.h6`
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px;
`;

const ModalContent = styled.div`
  background: #fff2;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
`;
const DetailsCon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  & span {
    display: flex;
    flex: 1;
    font-size: 16px;
  }
  & .label {
    color: #fff3;
  }
  & .value {
    color: #fffa;
  }
`;

const TextArea = styled.textarea`
  border: 1px solid #fff4;
  border-radius: 10px;
  padding: 20px;
  background: none;
  display: block;
  width: 100%;
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
`;

const SuspendBtn = styled.button`
  padding: 10px 20px;
  background: var(--secondary);
  color: #fff;
  border-radius: 10px;
`;
