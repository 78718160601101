import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import DashCards from "../component/Dashboard/DashCards";
import Header from "../component/Header";
import config from "../config/config";
import { GlobalContext } from "../context/provider";

function Dashboard() {
  const [BusinessNumber, setBusinessNumber] = useState([]);
  const [riderNumber, setRiderNumber] = useState([]);
  const [customersNumber, setCustomersNumber] = useState([]);

  const Businesss = async () => {
    try {
      const res = (
        await config.get(
          `admin/Business-management/list?page=1&limit=1000000000000000000000000000000000`
        )
      ).data;

      setBusinessNumber(res?.data?.length);
    } catch (error) {
      console.log("Error", error);
      setBusinessNumber(error?.response?.data?.message);
    }
  };
  const RiderManager = async () => {
    try {
      const res = (
        await config.get(
          `admin/rider-management/manager/list?page=1&limit=1000000`
        )
      ).data;
      console.log("ridrr", res);
      setRiderNumber(res?.data?.length);
    } catch (error) {
      console.log("Error", error);
      setRiderNumber(error?.response?.data?.message);
    }
  };
  const Customers = async () => {
    try {
      const res = (
        await config.get(
          `admin/customer-management/list?page=1&limit=1000000000000`
        )
      ).data;

      setCustomersNumber(res?.data?.length);
    } catch (error) {
      console.log("Error", error);
      setCustomersNumber(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    Customers();
    Businesss();
    RiderManager();
  }, []);

  const dashcards = [
    {
      label: "Customers",
      value: customersNumber,
      href: "/customer",
      color: "#f90",
    },
    {
      label: "Businesss",
      value: BusinessNumber,
      href: "/Business",
      //   color: "#f90",
    },
    {
      label: "Rider Managers",
      value: riderNumber,
      href: "/rider-manager",
      color: "#05a",
    },
  ];
  return (
    <div>
      <Header title="Dashboard" description="Welcome to ndali admin web v2.0" />

      <Row gutter={[20, 20]}>
        {dashcards.map((cur) => (
          <Col xs={12} md={8}>
            <DashCards {...cur} key={cur.href} />
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Dashboard;
