import {
  Alert,
  Button,
  Input,
  message,
  Modal,
  Popconfirm,
  Progress,
  Radio,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../component/Header";
import { EditFilled } from "@ant-design/icons";
import config from "../../config/config";

const dataSource = {
  key: "1",
  name: "Mike",
  email: "odunilade@gmail.com",
  date: "2nd aug, 2022",
  status: "active",
};

function Business() {
  const [formData1, setFormData1] = useState({
    model_type: "Businesss",
  });
  const [progress, setProgress] = useState(false);
  const [progressLength, setProgressLength] = useState(0);
  const [editing, setEditing] = useState(null);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    SuspendRestuarant();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [user_id, setUser_id] = useState("");
  const [formdata, setFormdata] = useState({
    entityID: editing?._id,
    model_type: "Businesss",
    duration: "24hrs",
    note: "",
  });
  const [value1, setValue1] = useState("24hrs");
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setFormdata({ ...formdata, duration: e.target.value });
  };
  console.log(formdata);

  const [susLoading, setSusLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [BusinessData, setBusinessData] = useState([]);

  console.log(user_id);
  const SuspendRestuarant = async () => {
    setProgress(true);
    setProgressLength(0);
    setFormdata({ ...formdata, entityID: user_id });
    try {
      setProgressLength(75);
      const res = (
        await config.post(`admin/suspension/suspend`, {
          ...formdata,
          entityID: user_id,
        })
      ).data;
      SelectedBusinesss();
      setProgressLength(100);
      console.log("suspend", res);
    } catch (error) {
      console.log("suspend", error);
      setError(error?.response?.data?.message);
    }
    setProgressLength(100);
    setIsModalOpen(false);
    setProgress(false);
  };
  const confirm = (e) => {
    console.log(e);

    UnSuspendRestuarant();
  };
  const cancel = (e) => {
    console.log(e);
  };

  const UnSuspendRestuarant = async () => {
    setFormData1({ ...formData1, model_type: "Business" });
    setSusLoading(true);

    try {
      const res = (
        await config.post(`/admin/suspension/${user_id}/unsuspend`, formData1)
      ).data;

      console.log("unsuspend", res);
      message.success("Unsuspended");
      SelectedBusinesss();
      setSusLoading(false);
    } catch (error) {
      console.log("suspend", error);
      setError(error?.response?.data?.message);
      message.error(error);
    }
  };
  const onClose = (e) => {
    console.log(e, "I was closed.");
  };
  const [dataSource, setDataSource] = useState([...BusinessData]);
  const [value, setValue] = useState("");

  const SelectedBusinesss = async () => {
    try {
      const res = (
        await config.get(
          `admin/business-management/getBusinessDetails/${user_id}`
        )
      ).data;
      console.log("sel", res);
      setEditing(res.data);
    } catch (error) {
      console.log("Error", error);
      setError(error?.response?.data?.message);
    }
  };
  const ApproveBusiness = async () => {
    setLoading(true);
    try {
      const res = (
        await config.patch(`admin/business-management/verify/${user_id}`)
      ).data;
      console.log("response", res);
      SelectedBusinesss();
      setLoading(false);
    } catch (error) {
      setError(error?.response?.data?.message);
      console.log("approve error", error);
    }
  };
  const Businesss = async () => {
    try {
      const res = (
        await config.get(
          `admin/business-management/list?page=1&limit=1000000000000000000000000000000000`
        )
      ).data;

      setBusinessData(res?.data);
      console.log(res.data);
    } catch (error) {
      console.log("Error", error);
      setError(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    SelectedBusinesss();
  }, [user_id]);

  useEffect(() => {
    Businesss();
  }, [value === ""]);

  const columns = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Date Joined",
      dataIndex: "createdAt",
      key: "",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, param) => {
        return (
          <Controls>
            <button
              onClick={() => {
                setUser_id(param?._id);
              }}
            >
              <EditFilled />
            </button>
          </Controls>
        );
      },
    },
  ];
  return (
    <div>
      <Header
        title="Businesss"
        description="Added, edit and delete Business accounts"
        button={{
          text: "+ Add Business",
          href: "/Business/add",
        }}
      />
      <Input
        placeholder="Search "
        size="middle"
        style={{ marginBottom: 20, height: 50, borderRadius: 15 }}
        value={value}
        onChange={(e) => {
          const currValue = e.target.value;
          setValue(currValue);
          const filteredData = BusinessData.filter((entry) =>
            entry.name.toLowerCase().includes(currValue.toLowerCase())
          );
          setBusinessData(filteredData);

          console.log("data", BusinessData);
        }}
      />
      <MyTable
        columns={columns}
        className="myTable"
        dataSource={[...BusinessData]}
      />
      {error && (
        <Alert message={error} type="warning" closable onClose={onClose} />
      )}
      <Modal
        title="Suspension Duration"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value="24hrs">24hrs</Radio>
              <Radio value="1m">1minite</Radio>
              <Radio value="1w">1 week</Radio>
              <Radio value="permanent">permanent</Radio>
            </Space>
          </Radio.Group>
          {progress && (
            <Progress
              style={{ marginRight: "20px" }}
              type="circle"
              percent={progressLength}
              format={() => "Suspend"}
            />
          )}
        </div>
        <TextArea
          placeholder="Note"
          required
          style={{ background: "#242424", marginTop: 15 }}
          value={formdata.note}
          onChange={(e) => {
            setFormdata({ ...formdata, note: e.target.value });
          }}
        ></TextArea>
      </Modal>
      <CustomModal
        // centered
        onCancel={() => setEditing(null)}
        visible={Boolean(editing)}
        width={800}
        footer={null}
      >
        <ModalTitle>Personal Details</ModalTitle>

        <ModalContent>
          <DetailsCon>
            <span className="label">First name</span>
            <span className="value">{editing?.owner_details?.firstName}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Last name</span>
            <span className="value">{editing?.owner_details?.lastName}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Email</span>
            <span className="value">{editing?.email}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Phone number</span>
            <span className="value">
              {editing?.owner_details?.dialCode}
              {editing?.owner_details?.phoneNumber}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Country</span>
            <span className="value">{editing?.name}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Address</span>
            <span className="value">{editing?.address}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">City</span>
            <span className="value"> {editing?.city}</span>
          </DetailsCon>
        </ModalContent>
        <ModalTitle>Payment details</ModalTitle>

        <ModalContent>
          <DetailsCon>
            <span className="label">Bank</span>
            <span className="value">{editing?.bank_details?.bank_name}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Account Name</span>
            <span className="value">{editing?.bank_details?.account_name}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Account Number</span>
            <span className="value">
              {editing?.bank_details?.account_number}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Paypal_Email</span>
            <span className="value">{editing?.bank_details?.paypal_email}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">recipient_code</span>
            <span className="value">
              {editing?.bank_details?.recipient_code}
            </span>
          </DetailsCon>
        </ModalContent>
        <ModalTitle>Business Details</ModalTitle>

        <ModalContent>
          <DetailsCon>
            <span className="label">Business name</span>
            <span className="value">{editing?.name}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Business slogan</span>
            <span className="value">{editing?.slogan}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Business email address</span>
            <span className="value">{editing?.Business_email}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Business Type</span>
            <span className="value">{editing?.Business_type}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Business address</span>
            <span className="value">{editing?.address}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Country</span>
            <span className="value">{editing?.name}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">City</span>
            <span className="value">{editing?.city}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Phone Number</span>
            <span className="value">
              {" "}
              {editing?.dialCode}
              {editing?.phoneNumber}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Hours</span>
            <span className="value">
              {editing?.rHours?.oHour}-{editing?.rHours?.cHour}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Categories</span>
            <span className="value">{editing?.name}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Services</span>
            <span className="value">
              {editing?.services.map((cur, i) => (
                <ul key={i}>
                  <li style={{ marginRight: 20 }}>{cur}</li>
                </ul>
              ))}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">AdminVerified</span>
            <span className="value">{editing?.adminVerified.toString()}</span>
          </DetailsCon>
        </ModalContent>

        <ModalTitle>Message</ModalTitle>

        <ModalContent>
          <label style={{ display: "block", color: "#fff8", marginBottom: 10 }}>
            Note
          </label>

          <TextArea />
          {error && (
            <Alert message={error} type="warning" closable onClose={onClose} />
          )}
          <Space
            direction="vertical"
            style={{
              width: "100%",
            }}
          >
            {editing?.adminVerified === true ? (
              <Button
                style={{ background: "#0DAD09", color: "#fff", height: 40 }}
                type="primary"
                block
                disabled
              >
                Approved
              </Button>
            ) : (
              <Button
                style={{ background: "#f90", color: "#fff" }}
                type="primary"
                block
                loading={loading}
                onClick={ApproveBusiness}
              >
                Approve
              </Button>
            )}
            {editing?.deactivationStatus === "none" ? (
              <Button
                style={{
                  background: "#fff",
                  color: "#242424",
                  marginTop: 2,
                  marginBottom: 2,
                  height: 40,
                }}
                type="primary"
                block
                onClick={showModal}
              >
                Suspend
              </Button>
            ) : (
              <Popconfirm
                title="Are you sure to unsuspend this user?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <a href="#" style={{ color: "#fff" }}>
                  <Button
                    style={{
                      background: "#242424",
                      color: "#fff",
                      marginBottom: 2,
                      marginTop: 2,
                      height: 40,
                    }}
                    type="primary"
                    block
                    loading={susLoading}
                  >
                    Unsuspend
                  </Button>
                </a>
              </Popconfirm>
            )}
            <Button
              style={{
                background: "#f90",
                color: "#fff",
                marginTop: 1,
                height: 40,
              }}
              type="primary"
              block
            >
              Deactivate
            </Button>
          </Space>
        </ModalContent>
      </CustomModal>
    </div>
  );
}

export default Business;

const MyTable = styled(Table)`
  & .ant-table {
    background: #fff1;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 40px #0006;
  }

  & .ant-table-thead {
    background: #fff4;
  }
  & .ant-table-cell {
    color: #fff;
    background: none;
    border-bottom: 1px solid #fff2;
  }
  & .ant-table-tbody > tr.ant-table-row:hover > td,
  & .ant-table-tbody > tr > td.ant-table-cell-row-hove {
    background: #fff3;
  }

  & .ant-pagination-item-link {
    background: var(--tertiary);
    color: #fff;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  & button {
    background: none;
    color: #fff;
    font-size: 16px;
  }
`;

const CustomModal = styled(Modal)`
  & .ant-modal-content {
    background: var(--tertiary);
    border-radius: 20px;
    box-shadow: 0 0 40px #0006;
  }
`;

const ModalTitle = styled.h6`
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px;
`;

const ModalContent = styled.div`
  background: #fff2;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
`;
const DetailsCon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  & span {
    display: flex;
    flex: 1;
    font-size: 16px;
  }
  & .label {
    color: #fff3;
  }
  & .value {
    color: #fffa;
  }
`;

const TextArea = styled.textarea`
  border: 1px solid #fff4;
  border-radius: 10px;
  padding: 20px;
  background: none;
  display: block;
  width: 100%;
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
`;

const SuspendBtn = styled.button`
  padding: 10px 20px;
  background: var(--secondary);
  color: #fff;
  border-radius: 10px;
`;
