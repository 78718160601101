import React from "react";
import styled from "styled-components";
import { SendOutlined } from "@ant-design/icons";
function ChatForm() {
  return (
    <Form>
      <Textfield placeholder="Type here.." />
      <Button>
        <SendOutlined />
      </Button>
    </Form>
  );
}

export default ChatForm;

const Form = styled.form`
  display: flex;
  align-items: center;
`;
const Textfield = styled.input`
  flex: 1;
  background: none;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #fff4;
  margin-right: 20px;

  &::placeholder {
    color: #fff4;
  }
`;
const Button = styled.button`
  background: none;
  font-size: 24px;
`;
