import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import DashCards from "../../component/Dashboard/DashCards";
import Header from "../../component/Header";
import config from "../../config/config";
import { GlobalContext } from "../../context/provider";

function Dashboard() {
  const [BusinessNumber, setBusinessNumber] = useState(0);
  const [riderNumber, setRiderNumber] = useState(0);
  const [customersNumber, setCustomersNumber] = useState(0);
  const [orderTransectionNumber, setOrderTransectionNumber] = useState();

  const OrderTransaction = async () => {
    try {
      const res = (
        await config.get(`/admin/payment-management/list?page=1&limit=1000000`)
      ).data;
      setOrderTransectionNumber(res?.data?.length);
      console.log("data", res);
    } catch (error) {
      console.log("Error", error);
      setOrderTransectionNumber(error?.response?.data?.message);
    }
  };
  // const RiderManager = async () => {
  //   try {
  //     const res = (
  //       await config.get(
  //         `admin/rider-management/manager/list?page=1&limit=1000000`
  //       )
  //     ).data;
  //     console.log("ridrr", res);
  //     setRiderNumber(res?.data?.length);
  //   } catch (error) {
  //     console.log("Error", error);
  //     setRiderNumber(error?.response?.data?.message);
  //   }
  // };
  // const Customers = async () => {
  //   try {
  //     const res = (
  //       await config.get(
  //         `admin/customer-management/list?page=1&limit=1000000000000`
  //       )
  //     ).data;

  //     setCustomersNumber(res?.data?.length);
  //   } catch (error) {
  //     console.log("Error", error);
  //     setCustomersNumber(error?.response?.data?.message);
  //   }
  // };

  useEffect(() => {
    OrderTransaction();
  }, []);

  const dashcards = [
    {
      label: "OrderTransaction",
      value: orderTransectionNumber,
      href: "ordertransaction",
      color: "#f90",
    },
    {
      label: "Subcription Transaction",
      value: BusinessNumber,
      href: "/Business",
      //   color: "#f90",
    },
    {
      label: "Payment",
      value: riderNumber,
      href: "/rider-manager",
      color: "#05a",
    },
  ];
  return (
    <div>
      <Header title="Payment" description="Payment Details" />

      <Row gutter={[20, 20]}>
        {dashcards.map((cur) => (
          <Col xs={12} md={8}>
            <DashCards {...cur} key={cur.href} />
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Dashboard;
