import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function Header({ title, description, button, text, right }) {
  return (
    <Root>
      <Headers>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Headers>
      {right && (
        <div style={{ background: "#fff", padding: 10, borderRadius: 10 }}>
          {text}
        </div>
      )}
      {button && <Button to={button.href}>{button.text}</Button>}
    </Root>
  );
}

export default Header;

const Root = styled.div`
  margin-bottom: 14px;
  display: flex;
  align-items: center;
`;

const Headers = styled.div`
  flex: 1;
`;

const Title = styled.h2`
  color: #fff;
  margin-bottom: 0px;
  font-size: 30px;
`;

const Description = styled.p`
  color: #fff7;
`;

const Button = styled(Link)`
  background: none;
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
`;
