import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function DashCards({ label, value, color, href }) {
  return (
    <Link to={href}>
      <Container style={{ background: color }}>
        <Label>{label}</Label>
        <Value>{value}</Value>
      </Container>
    </Link>
  );
}

export default DashCards;

const Container = styled.div`
  border-radius: 10px;
  background: #fff4;
  padding: 30px 20px;
`;

const Label = styled.h5`
  color: #fffa;
  font-size: 16px;
  margin-bottom: 5px;
`;

const Value = styled.div`
  color: #fff;
  font-size: 26px;
  margin-bottom: 0px;
`;
