import React from "react";
import Header from "../../../component/Header";
import {
  Avatar,
  Button,
  Divider,
  Input,
  Menu,
  Modal,
  Pagination,
  Progress,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";

import hello from "../../../assets/images/hello.jpg";
import product from "../../../assets/images/test.jpg";
import styled from "styled-components";
import { AppstoreOutlined, MailOutlined, StarFilled } from "@ant-design/icons";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import config from "../../../config/config";
import { useEffect } from "react";
const array = [1, 2, 3, 4, 5, 6];
const items = [
  {
    label: "Active",
    key: "active",
    icon: <MailOutlined />,
  },
  {
    label: "Assigned",
    key: "assigned",
    icon: <AppstoreOutlined />,
  },
  {
    label: "Suspended",
    key: "suspended",
    icon: <AppstoreOutlined />,
  },
];

function RiderManagerInfo() {
  const size = 3;
  const [page, setPage] = useState(1);
  const [current, setCurrent] = useState("active");

  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [active, setActive] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [riders, setRiders] = useState([]);
  const id = queryParams.get("id");
  const [orders, setOrders] = useState([]);
  const [formData, setFormData] = useState({
    riderId: "",
    orderId: "",
  });

  const getAllUnassignedOrder = async () => {
    try {
      const res = (
        await config.get(`/admin/order-management/unassigned-orders`)
      ).data;
      console.log("orders", res.data);
      const p = res.data;
      setOrders(p.reverse());
    } catch (error) {
      console.log("error fetching orders", error.response);
    }
  };
  const pagedResult = React.useMemo(() => {
    const start = (page - 1) * size;
    const end = page * size;
    const sliced = orders.slice(start, end);
    return sliced;
  }, [orders, page, size]);
  const getRiderDetails = async () => {
    try {
      const res = (
        await config.get(`/admin/rider-management/getManagerRiders/${id}`)
      ).data;
      console.log("rider manger info", res.data);
      setRiders(res.data);
    } catch (error) {
      console.log("error fetching rider manager info", error.response);
    }
  };

  useEffect(() => {
    getRiderDetails();
    getAllUnassignedOrder();
  }, [id]);
  const [loading, setLoading] = useState(false);
  const AssignRider = async () => {
    setLoading(true);
    try {
      const res = (
        await config.post(
          "/admin/rider-management/assign-rider-to-order",
          formData
        )
      ).data;
      console.log("rider assigned", res.data);
      alert(
        "rider Assigned.. notification will be sent to both customer and business"
      );
    } catch (error) {
      console.log("error assigning rider", error.response);
      alert(error.response.data.message);
    }
    setLoading(false);
  };
  console.log("parameter", formData);
  const total = Math.ceil(orders.length / size);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Header title="Ndali Rider" description="Best Choice" />
        <Link to={`rider/add?id=${id}`}>
          <Button
            style={{
              marginTop: 5,
              background: "#28a745",
              border: "none",
              color: "#fff",
              borderRadius: 10,
            }}
          >
            Create Rider
          </Button>
        </Link>
      </div>
      <Modal
        width="50vw"
        open={isModalOpen}
        footer={
          <div>
            <Button
              onClick={() => {
                AssignRider();
                handleCancel();
              }}
              disabled={active === false}
            >
              {loading ? <Progress /> : "  Assign"}
            </Button>
          </div>
        }
        style={{ borderRadius: 10, height: "50vh" }}
        onCancel={handleCancel}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <div style={{ width: "40%" }}>
            <Box>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Switch defaultChecked />
              </div>
              <Avatar size={100} src={hello} />
              <div style={{ marginTop: 2, marginBottom: 2 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 1,
                    marginBottom: 1,
                    justifyContent: "center",
                  }}
                >
                  <StarFilled color="#f90" style={{ color: "#f90" }} />
                  <StarFilled color="#f90" style={{ color: "#f90" }} />
                  <StarFilled color="#f90" style={{ color: "#f90" }} />
                  <StarFilled color="#f90" style={{ color: "#f90" }} />
                </div>
                <Typography style={{ fontWeight: 600 }}>Kimjay</Typography>
                <Typography style={{ fontWeight: 600 }}>09065764742</Typography>
                <Typography style={{ fontWeight: 600 }}>
                  Olawaleadeit@gmail.com
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Typography>
                  <span style={{ fontWeight: 600 }}> Description </span> : I
                  certify that the information given on this form is true and
                  correct to the best of my knowledge. I understand that as a
                  volunteer driver, I must be 18 years of age or older, possess
                  a valid driver’s license, have the proper and current license
                  and vehicle registration, and have the required insurance
                  coverage in effect on any vehicle used to transport
                  participants of the event.
                </Typography>
              </div>
            </Box>
          </div> */}
          {/* <Divider orientation="left" dashed type="vertical" /> */}
          <div
            style={{
              width: "100%",

              padding: 5,
            }}
          >
            <Typography
              style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}
            >
              Pending Orders
            </Typography>
            {pagedResult.map((cur, i) => (
              <div
                key={i}
                onClick={() => {
                  setFormData({ ...formData, orderId: cur._id });
                }}
                style={
                  formData.orderId === cur._id
                    ? {
                        background: "#f7f7f7",
                        padding: 10,
                        borderRadius: 10,
                        border: "solid #28a745 2px",
                        marginBottom: 5,
                      }
                    : {
                        background: "#f7f7f7",
                        padding: 10,
                        borderRadius: 10,
                        cursor: "pointer",
                        marginBottom: 5,
                      }
                }
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 10,
                    }}
                  >
                    <img
                      src={cur.orderItems[0]?.product?.media[0]?.url}
                      style={{ height: 110, width: 110, objectFit: "contain" }}
                    />
                    <div>
                      <Typography sx={{ color: "#aaa" }}>
                        {cur.orderNumber}
                      </Typography>
                      <Typography sx={{ color: "#aaa" }}>
                        <span style={{ fontWeight: 600, color: "#000" }}>
                          OrderItems
                        </span>{" "}
                        : {cur.orderItems?.length}
                      </Typography>
                      <Typography sx={{ color: "#aaa" }}>
                        {" "}
                        <span style={{ fontWeight: 600, color: "#000" }}>
                          SubTotal
                        </span>
                        : {cur.subTotal}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    <span style={{ fontWeight: 600, color: "#000" }}>
                      Payment Status
                    </span>{" "}
                    : {cur.paymentStatus}
                  </Typography>
                  <Typography sx={{ color: "#aaa" }}>
                    <span style={{ fontWeight: 600, color: "#000" }}>
                      Total
                    </span>{" "}
                    : {cur.totalAmount}
                  </Typography>
                </div>
              </div>
            ))}

            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                onChange={(e) => {
                  setPage(e);
                }}
                defaultCurrent={1}
                total={90}
              />
            </div>
          </div>
        </div>
      </Modal>
      <div>
        <Menu
          style={{
            background: "#f7f7f7",
            borderRadius: 10,
            marginBottom: 20,
            fontWeight: 600,
          }}
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          {riders.map((cur, i) => (
            <Box key={i}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Switch
                  checked={cur.status}
                  style={{
                    background: cur.status === "active" ? "#28a745" : "#aaa",
                  }}
                />
              </div>
              <Avatar size={100} src={hello} />
              <div style={{ marginTop: 2, marginBottom: 2 }}>
                <Typography style={{ fontWeight: 600 }}>
                  {cur.firstName + " " + cur.lastName}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 1,
                    marginBottom: 1,
                    justifyContent: "center",
                  }}
                >
                  <StarFilled color="#f90" style={{ color: "#f90" }} />
                  <StarFilled color="#f90" style={{ color: "#f90" }} />
                  <StarFilled color="#f90" style={{ color: "#f90" }} />
                  <StarFilled color="#f90" style={{ color: "#f90" }} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Button
                  size="small"
                  type="primary"
                  style={{
                    background: "#1e2936",
                    border: "#000",
                    width: "100%",
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    setFormData({ ...formData, riderId: cur._id });
                    showModal();
                  }}
                >
                  Assign Rider
                </Button>
              </div>
            </Box>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RiderManagerInfo;

const Box = styled.div`
  background: #f7f7f7;
  padding: 15px;
  border-radius: 10px;
  min-width: 180px;
  min-height: 220px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
`;
