import React from "react";
import styled from "styled-components";
import Header from "../../component/Header";

function AddRiderManager() {
  return (
    <div>
      <Header title="Add New Customer" description="Added some decscription" />
      <Form>
        <FormGroup>
          <Label>First Name</Label>
          <Input placeholder="eg: John" />
        </FormGroup>
        <FormGroup>
          <Label>Last Name</Label>
          <Input placeholder="eg: Doe" />
        </FormGroup>
        <FormGroup>
          <Label>Email Address</Label>
          <Input placeholder="eg: example@ndali.com" />
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <Input placeholder="eg: ****" />
        </FormGroup>
        <FormGroup>
          <Label>Address</Label>
          <Input placeholder="eg: ****" />
        </FormGroup>

        <Button type="submit">Create user</Button>
      </Form>
    </div>
  );
}

export default AddRiderManager;

const Form = styled.form`
  max-width: 500px;
`;
const Input = styled.input`
  padding: 10px;
  padding: 10px;
  display: block;
  border-radius: 10px;
  width: 100%;
  background: #fff2;
  color: #fff;

  &::placeholder {
    color: #fff5;
  }
`;
const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin-bottom: 10px;
  padding-left: 10px;
  display: block;
  color: #fffa;
`;

const Button = styled.button`
  padding: 15px 30px;
  color: #fff;
  background: var(--secondary);
  border-radius: 10px;
  font-size: 16px;
  margin-top: 20px;
`;
