import { Col, Modal, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../component/Header";
import config from "../../config/config";
import { GlobalContext } from "../../context/provider";
import countryCodes from "../../utils/countryCodes";

function AddBusiness() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { bankState } = useContext(GlobalContext);
  const [restored, setRestored] = useState(false);
  const [formData, setFormData] = useState({
    account_name: "",
    account_number: "",
    bank_code: "",
    bank_name: "United bank of people",
  });
  console.log("formdata", formData);
  useEffect(() => {
    if (restored)
      setFormData({
        ...formData,
        account_name: "",
      });
    if (
      formData.bank_name.length > 0 &&
      formData.account_number.length === 10
    ) {
      const bank_code = bankState.data.find(
        (item) => item.name === formData.bank_name
      )?.code;

      config
        .get(
          `payment/resolveAccount?account_number=${
            formData.account_number
          }&bank_code=0${parseInt(bank_code)}`
        )
        .then((res) => {
          setFormData({
            ...formData,
            bank_code,
            account_name: res.data.data.account_name,
          });
        })
        .catch((error) => {
          console.log("bank error", error.response);
        });
    }
  }, [formData.bank_name, formData.account_number]);

  return (
    <div>
      <Header
        title="Add New Business"
        description="Setup a new Business account"
      />
      <Form>
        <FormGroup>
          <Label>First Name</Label>
          <Input placeholder="eg: John" />
        </FormGroup>
        <FormGroup>
          <Label>Last Name</Label>
          <Input placeholder="eg: Doe" />
        </FormGroup>
        <FormGroup>
          <Label>Email Address</Label>
          <Input placeholder="eg: example@ndali.com" />
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <Input placeholder="eg: ****" />
        </FormGroup>
        <FormGroup>
          <Label>Address</Label>
          <Input placeholder="eg: ****" />
        </FormGroup>

        <FormGroup>
          <Row gutter={15}>
            <Col span={10}>
              <Label>Country code</Label>
              <Select
                value={formData.dialCode}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    dialCode: e.target.value,
                  });
                }}
                placeholder="Nigeria +234"
              >
                {countryCodes.map((cur) => (
                  <option
                    value={cur.code}
                    key={cur.code}
                  >{`${cur.dial_code} ${cur.name}`}</option>
                ))}
              </Select>
            </Col>
            <Col span={14}>
              <Label>Phone number</Label>
              <Input
                value={formData.phoneNumber}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    phoneNumber: e.target.value,
                  });
                }}
                placeholder="eg: 8089040350"
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Label>Bank Name</Label>
          <Button
            block
            style={{
              background: "#fff2",
              width: "100%",
              height: 45,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 10,
              fontSize: 14,
              fontWeight: 500,
            }}
            onClick={(e) => {
              e.preventDefault();
              setIsModalOpen(true);
            }}
          >
            {formData.bank_name}
          </Button>
          <Modal
            title="Bank Name"
            open={isModalOpen}
            footer={null}
            style={{ padding: 20 }}
          >
            {bankState.data.map((cur, index) => (
              <h3
                style={{ cursor: "pointer", "& h3:hover": {} }}
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({ ...formData, bank_name: cur.name });
                  setIsModalOpen(false);
                }}
              >
                {cur.name}
              </h3>
            ))}
          </Modal>
        </FormGroup>
        <FormGroup>
          <Label>Account name</Label>
          <Input
            placeholder="Enter Account Name"
            value={formData.account_name}
            onChange={(e) => {
              setFormData({ ...formData, account_name: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Account number</Label>
          <Input
            placeholder="Enter Account Number"
            value={formData.account_number}
            onChange={(e) => {
              setFormData({ ...formData, account_number: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Routing / IFSC / Short code</Label>
          <Input placeholder="Enter Account Number" />
        </FormGroup>
        <Button type="submit">Create user</Button>
      </Form>
    </div>
  );
}

export default AddBusiness;

const Form = styled.form`
  max-width: 500px;
`;
const Input = styled.input`
  padding: 10px;
  padding: 10px;
  display: block;
  border-radius: 10px;
  width: 100%;
  background: #fff2;
  color: #fff;

  &::placeholder {
    color: #fff5;
  }
`;
const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin-bottom: 10px;
  padding-left: 10px;
  display: block;
  color: #fffa;
`;

const Button = styled.button`
  padding: 15px 30px;
  color: #fff;
  background: var(--secondary);
  border-radius: 10px;
  font-size: 16px;
  margin-top: 20px;
`;

const Select = styled.select`
  border-radius: 10px;
  width: 100%;
  padding: 12px;
  background: #fff2;
  color: #fff;

  &::placeholder {
    color: #fff5;
  }
`;
