import { Input, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../component/Header";
import { EditFilled } from "@ant-design/icons";
import config from "../../config/config";

const dataSource = {
  key: "1",
  name: "Mike",
  email: "odunilade@gmail.com",
  date: "2nd aug, 2022",
  status: "active",
};

function AdminManagement() {
  const [editing, setEditing] = useState(null);
  const [user_id, setUser_id] = useState("");
  const [adminData, setAdminData] = useState([]);
  const selectedAdmin = async () => {
    try {
      const res = (
        await config.get(`admin/admin-management/getAdminDetails/${user_id}`)
      ).data;
      console.log("sel", res);
      setEditing(res.data);
    } catch (error) {
      console.log("Error", error);
    }
  };
  const admin = async () => {
    try {
      const res = (await config.get(`admin/admin-management/getAllAdmin`)).data;

      setAdminData(res?.data);
      console.log(res.data);
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    selectedAdmin();
  }, [user_id]);
  const [dataSource, setDataSource] = useState([...adminData]);
  const [value, setValue] = useState("");
  useEffect(() => {
    admin();
  }, [value === ""]);
  const columns = [
    {
      title: "Admin ID",
      dataIndex: "adminID",
      key: "adminID",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Date Joined",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, param) => {
        return (
          <Controls>
            <button
              onClick={() => {
                setUser_id(param._id);
              }}
            >
              <EditFilled />
            </button>
          </Controls>
        );
      },
    },
  ];
  return (
    <div>
      <Header
        title="Sub-Admins"
        description="Added, edit and delete sub admins"
        button={{
          text: "+ Add Sub-Admin",
          href: "/admin-manager/add",
        }}
      />
      <Input
        placeholder="Search "
        size="middle"
        style={{ marginBottom: 20, height: 50, borderRadius: 15 }}
        value={value}
        onChange={(e) => {
          const currValue = e.target.value;
          setValue(currValue);
          const filteredData = adminData.filter((entry) =>
            entry.name.includes(currValue)
          );
          setAdminData(filteredData);
        }}
      />
      <MyTable
        columns={columns}
        className="myTable"
        dataSource={[...adminData]}
      />

      <CustomModal
        // centered
        onCancel={() => setEditing(null)}
        visible={Boolean(editing)}
        width={800}
        footer={null}
      >
        <ModalTitle>AdminManagement Details</ModalTitle>

        <ModalContent>
          <DetailsCon>
            <span className="label">First name</span>
            <span className="value">{editing?.firstName}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Last name</span>
            <span className="value">{editing?.lastName}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Email</span>
            <span className="value">{editing?.email}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Phone number</span>
            <span className="value">
              {editing?.dialCode}
              {editing?.phoneNumber}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Permissions:</span>
            <span className="value">
              {editing?.permissions?.map((cur, i) => (
                <div key={i} style={{ marginLeft: 10 }}>
                  {cur},
                </div>
              ))}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Active</span>
            <span className="value">{editing?.active?.toString()}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Online</span>
            <span className="value">{editing?.online?.toString()}</span>
          </DetailsCon>
        </ModalContent>

        <ModalTitle>Suspension</ModalTitle>

        <ModalContent>
          <label style={{ display: "block", color: "#fff8", marginBottom: 10 }}>
            Note
          </label>

          <TextArea />

          <SuspendBtn>Suspend</SuspendBtn>
        </ModalContent>
      </CustomModal>
    </div>
  );
}

export default AdminManagement;

const MyTable = styled(Table)`
  & .ant-table {
    background: #fff1;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 40px #0006;
  }

  & .ant-table-thead {
    background: #fff4;
  }
  & .ant-table-cell {
    color: #fff;
    background: none;
    border-bottom: 1px solid #fff2;
  }
  & .ant-table-tbody > tr.ant-table-row:hover > td,
  & .ant-table-tbody > tr > td.ant-table-cell-row-hove {
    background: #fff3;
  }

  & .ant-pagination-item-link {
    background: var(--tertiary);
    color: #fff;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  & button {
    background: none;
    color: #fff;
    font-size: 16px;
  }
`;

const CustomModal = styled(Modal)`
  & .ant-modal-content {
    background: var(--tertiary);
    border-radius: 20px;
    box-shadow: 0 0 40px #0006;
  }
`;

const ModalTitle = styled.h6`
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px;
`;

const ModalContent = styled.div`
  background: #fff2;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
`;
const DetailsCon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  & span {
    display: flex;
    flex: 1;
    font-size: 16px;
  }
  & .label {
    color: #fff3;
  }
  & .value {
    color: #fffa;
  }
`;

const TextArea = styled.textarea`
  border: 1px solid #fff4;
  border-radius: 10px;
  padding: 20px;
  background: none;
  display: block;
  width: 100%;
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
`;

const SuspendBtn = styled.button`
  padding: 10px 20px;
  background: var(--secondary);
  color: #fff;
  border-radius: 10px;
`;
