import "./App.less";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./Layouts/MainLayout";
import AddCustomer from "./pages/Customer/AddCustomer";
import Customer from "./pages/Customer";
import AddBusiness from "./pages/Business/AddBusiness";
import Business from "./pages/Business";
import AddRiderManager from "./pages/RiderManager/AddRiderManager";
import RiderManager from "./pages/RiderManager/";
import Dashboard from "./pages/Dashboard";

import AddAdminManager from "./pages/sub-admins/AddAdmin";
import Payment from "./pages/Payment";
import SubAdmins from "./pages/sub-admins";
import Logout from "./pages/logout";
import PaymentDetails from "./pages/Payment/PaymentDetails";
import OrderTransaction from "./pages/Payment/OrderTransaction";
import RiderManagerInfo from "./pages/RiderManager/Info/RiderManagerInfo";
import AddRider from "./pages/RiderManager/Info/AddRider";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="customer/add" element={<AddCustomer />} />
          <Route path="customer" element={<Customer />} />
          <Route path="Business/add" element={<AddBusiness />} />
          <Route path="Business" element={<Business />} />
          <Route path="rider-manager/add" element={<AddRiderManager />} />
          <Route path="rider-manager" element={<RiderManager />} />
          <Route path="rider-manager/info" element={<RiderManagerInfo />} />
          <Route path="rider-manager/info/rider/add" element={<AddRider />} />
          <Route path="admin-manager/add" element={<AddAdminManager />} />
          <Route path="sub-admins" element={<SubAdmins />} />
          <Route path="payment/:id" element={<PaymentDetails />} />
          <Route path="payment" element={<Payment />} />
          <Route
            path="payment/ordertransaction"
            element={<OrderTransaction />}
          />

          <Route path="logout" element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
