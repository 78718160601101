import React from "react";
import Header from "../../../component/Header";
import { Avatar, Button, Input, Progress, Spin } from "antd";
import { useState } from "react";
import hello from "../../../assets/images/hello.jpg";
import config from "../../../config/config";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { useEffect } from "react";

function AddRider() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const id = queryParams.get("id");
  const [formData, setFormData] = useState({
    managerID: "", // Fixed for now
    firstName: "",
    lastName: "",
    //"email": "odunmi@gmail.com",
    phoneNumber: "",
    vehicleNumber: "",
  });
  useEffect(() => {
    setFormData({ ...formData, managerID: id });
  }, [id]);
  console.log(formData, "formdata");
  const CreateRider = async () => {
    setLoading(true);
    try {
      const res = (
        await config.post("/admin/rider-management/create-rider", formData)
      ).data;
      console.log("creating rider", res.data);
      navigate(-1);
    } catch (error) {
      console.log("error creating rider", error.response);
      alert(error.response.data.message);
    }
    setLoading(false);
  };
  return (
    <div>
      <Header title="Add New Rider" description="Added some decscription" />
      <div
        style={{
          background: "#f7f7f7",
          borderRadius: 10,
          padding: 20,
          maxWidth: 600,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
            marginTop: 30,
          }}
        >
          <Avatar size={120} src={hello} />
        </div>
        <Input
          placeholder="First Name"
          size="large"
          style={{ marginBottom: 10, borderRadius: 10 }}
          value={formData.firstName}
          onChange={(e) => {
            setFormData({ ...formData, firstName: e.target.value });
          }}
        />
        <Input
          placeholder="Last Name"
          size="large"
          style={{ marginBottom: 10, borderRadius: 10 }}
          value={formData.lastName}
          onChange={(e) => {
            setFormData({ ...formData, lastName: e.target.value });
          }}
        />
        <Input
          placeholder="Phone Number"
          size="large"
          style={{ marginBottom: 10, borderRadius: 10 }}
          value={formData.phoneNumber}
          onChange={(e) => {
            setFormData({ ...formData, phoneNumber: e.target.value });
          }}
        />
        <Input
          placeholder="VehicleNumber"
          size="large"
          style={{ marginBottom: 10, borderRadius: 10 }}
          value={formData.vehicleNumber}
          onChange={(e) => {
            setFormData({ ...formData, vehicleNumber: e.target.value });
          }}
        />
        <div>
          <Button
            onClick={CreateRider}
            style={{
              background: "#28a745",
              border: "none",
              color: "#fff",
              width: "100%",
              borderRadius: 10,
            }}
          >
            {loading ? <Spin /> : "  Create Rider"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddRider;
