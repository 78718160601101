import React, { useState } from "react";
import styled from "styled-components";
import Header from "../../component/Header";
import ChatBubble from "../../component/Messging/ChatBubble";
import ChatForm from "../../component/Messging/ChatForm";

const chats = [
  {
    sender: "ndali",
    message:
      "Hello!\nWe apologize for the inconvenience and assure you that your payment would be refunded",
    date: Date.now(),
  },
  {
    sender: "odunmilade",
    message: "Alright",
    date: Date.now(),
  },
];

function PaymentDetails() {
  const [data, setData] = useState({
    amount: "$130",
    receiver: "Ndali",
    sender: "odunilade@gmail.com",
    narration: "Subscription",
    date: "2nd aug, 2022",
    status: "successfull",
  });
  return (
    <>
      <Header title="Payment Details" description="Added some decscription" />
      <Root>
        <LeftContainer>
          <DetailsContainer>
            <DetailsCon>
              <span className="label">Sender</span>
              <span className="value">{data?.sender}</span>
            </DetailsCon>
            <DetailsCon>
              <span className="label">Receiver </span>
              <span className="value">{data?.receiver}</span>
            </DetailsCon>
            <DetailsCon>
              <span className="label">Amount</span>
              <span className="value">{data?.amount}</span>
            </DetailsCon>
            <DetailsCon>
              <span className="label">Narration</span>
              <span className="value">{data?.narration}</span>
            </DetailsCon>
            <DetailsCon>
              <span className="label">Status</span>
              <span className="value">{data?.status}</span>
            </DetailsCon>
          </DetailsContainer>

          <Button>Message</Button>
          <Button style={{ background: "#fff3" }}>Refund</Button>
        </LeftContainer>

        <MessageContainer>
          <ChatHeader>
            <span>Ndali - odunmilade@gmail.com</span>
          </ChatHeader>

          <ChatBody>
            {chats.map((cur) => (
              <ChatBubble {...cur} key={cur.sender} />
            ))}
          </ChatBody>
          <ChatFooter>
            <ChatForm />
          </ChatFooter>
        </MessageContainer>
      </Root>
    </>
  );
}

export default PaymentDetails;

const Root = styled.div`
  display: flex;
`;

const LeftContainer = styled.div`
  width: 30vw;
  margin-right: 20px;
`;

const DetailsContainer = styled.div`
  background: #fff2;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
`;
const DetailsCon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  & span {
    display: flex;
    flex: 1;
    font-size: 16px;
  }
  & .label {
    color: #fff3;
  }
  & .value {
    color: #fffa;
  }
`;

const TextArea = styled.textarea`
  border: 1px solid #fff4;
  border-radius: 10px;
  padding: 20px;
  background: none;
  display: block;
  width: 100%;
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background: var(--secondary);
  color: #fff;
  border-radius: 10px;
  margin-right: 20px;
`;

const MessageContainer = styled.div`
  background: #fff2;
  border-radius: 10px;
  width: 30vw;
  overflow: hidden;
`;

const ChatHeader = styled.div`
  padding: 15px 20px;
  background: #fff3;
`;

const ChatBody = styled.div`
  height: 50vh;
  overflow: auto;
`;

const ChatFooter = styled.div`
  padding: 20px;
  box-shadow: 0 -5px 10px #0002;
`;
