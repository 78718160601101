import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function Index() {
  const router = useNavigate();
  useEffect(() => {
    localStorage.clear();
    router(0);
  }, []);

  return <div></div>;
}

export default Index;
