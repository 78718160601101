import { createContext, useEffect, useReducer } from "react";
import config from "../config/config";
import { getCurrentUser } from "./auth/action";
import authReducer from "./auth/reducer";
import genericReducer, { defaultData } from "./reducers/genericReducer";

export const defaultAuth = {
  loggedIn: false,
  mode: null,
  data: {},
  setup_data: {
    email: "",
  },
  loading: false,
  error: null,
};

export const GlobalContext = createContext({
  auth: defaultAuth,
  authDispatch: () => {},
  bankState: defaultData,
});

const GlobalProvider = ({ children }) => {
  const [auth, authDispatch] = useReducer(authReducer, defaultAuth);

  const [bankState, bankDispatch] = useReducer(genericReducer, defaultData);

  useEffect(() => {
    getCurrentUser(authDispatch);
  }, [authDispatch]);
  useEffect(() => {
    (async () => {
      const banks = (await config.get("payment/banks")).data;

      bankDispatch({
        type: "FETCHED_DATA",
        payload: banks.data,
      });
      console.log(banks);
    })();
  }, []);
  return (
    <GlobalContext.Provider
      value={{ auth, authDispatch, bankState, bankDispatch }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
