import React, { useContext, useState } from "react";
import { Link, useLocation, Outlet } from "react-router-dom";
import styled from "styled-components";
import {
  MenuOutlined,
  UserOutlined,
  BellFilled,
  SettingFilled,
  DashboardOutlined,
} from "@ant-design/icons";

import logo from "../assets/images/logo.png";
import loader from "../assets/images/loader.png";
import { Avatar, Button } from "antd";
import Auth from "../component/Auth";
import { GlobalContext } from "../context/provider";

function MainLayout() {
  const { auth } = useContext(GlobalContext);
  console.log("logs", auth.loggedIn);
  const [loaded, setLoaded] = useState(false);

  const { pathname } = useLocation();

  const routes = [
    {
      label: "Dashboard",
      href: "/",
      active: pathname === "/",
      icon: <DashboardOutlined />,
    },
    {
      label: "Customer",
      icon: <DashboardOutlined />,
      active: pathname.indexOf("customer") > 0,
      href: "/customer",
    },
    {
      label: "Business",
      icon: <DashboardOutlined />,
      active: pathname.indexOf("Business") > 0,
      href: "/Business",
    },
    {
      label: "Rider Manager",
      icon: <DashboardOutlined />,
      active: pathname.indexOf("rider-manager") > 0,
      href: "/rider-manager",
    },
    {
      label: "Payment",
      icon: <DashboardOutlined />,
      href: "/payment",
      active: pathname.indexOf("payment") > 0,
    },
    {
      label: "Wallet",
      icon: <DashboardOutlined />,
      href: "/",
    },
    {
      label: "Coupon",
      icon: <DashboardOutlined />,
      href: "/",
    },
    {
      label: "Sub-Admins",
      icon: <DashboardOutlined />,
      active: pathname.indexOf("sub-admins") > 0,
      href: "/sub-admins",
    },
    {
      label: "LogOut",
      icon: <DashboardOutlined />,
      active: pathname.indexOf("sub-admins") > 0,
      href: "/logout",
    },
  ];
  React.useEffect(() => {
    const handler = () => setLoaded(true);
    if (document.readyState === "complete") {
      handler();
    } else {
      window.addEventListener("load", handler);
      return () => document.removeEventListener("load", handler);
    }
  }, []);
  return (
    <Root>
      <Toolbar>
        <MenuButton>
          <MenuOutlined />
        </MenuButton>

        <Logo src={logo} alt="" />
        <h3>ndali Admin</h3>
        <Right>
          {auth.loggedIn ? (
            <>
              <IconButton>
                <SettingFilled />
              </IconButton>
              <IconButton>
                <BellFilled />
              </IconButton>
              <Avatar icon={<UserOutlined />} />
              <h6>{auth.data.adminID}</h6>
            </>
          ) : (
            <>
              <Button type="primary">Sign in</Button>
            </>
          )}
        </Right>
      </Toolbar>
      {!loaded && (
        <Loader>
          <img src={loader} alt="" />
        </Loader>
      )}
      {auth.loggedIn ? (
        <Container>
          <SideBar>
            {routes.map((cur) => (
              <Link to={cur.href} key={cur.label}>
                <li className={cur.active ? "active" : ""}>
                  {cur.icon} <span className="text">{cur.label}</span>
                </li>
              </Link>
            ))}
          </SideBar>
          <Content>
            <Outlet />
          </Content>
        </Container>
      ) : (
        <Auth />
      )}
    </Root>
  );
}

export default MainLayout;

const Root = styled.div``;
const Toolbar = styled.nav`
  padding: 10px 20px;
  background: var(--tertiary);
  color: #fff;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  & h3 {
    text-transform: uppercase;
    margin-bottom: 0;
    color: #fff;
    font-size: 26pz;
  }
`;
const MenuButton = styled.button`
  background: none;
  font-size: 20px;
  margin-right: 30px;
`;

const Logo = styled.img`
  height: 50px;
  display: block;
  margin-right: 30px;
`;

const Right = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  & h6 {
    margin-bottom: 0;
    color: #fff7;
    font-size: 16px;
    margin-left: 20px;
  }
`;

const IconButton = styled.button`
  background: none;
  font-size: 28px;
  color: #fffa;
  margin-right: 15px;
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tertiary);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const Container = styled.main`
  display: flex;
  height: 100%;
`;
const SideBar = styled.div`
  position: sticky;
  top: 64px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  padding: 20px;
  width: 300px;
  // border-right: 1px solid #fff2;
  & li {
    color: #fff7;
    font-size: 18px;
    padding: 10px 20px;
    display: flex;
    border-radius: 10px;
    margin-bottom: 10px;
    align-items: center;
    transition: 0.2s;
    & .text {
      font-weight: 700;
      display: block;
      margin-left: 20px;
    }

    &:hover,
    &.active {
      color: #fffd;
      background: #fff1;
    }
  }
`;

const Content = styled.div`
  padding: 20px;
  flex: 1;
  @media (min-width: 1200px) {
    padding: 30px 5vw;
  }
`;
