import {
  Alert,
  Button,
  Input,
  Modal,
  Popconfirm,
  Progress,
  message,
  Radio,
  Space,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../component/Header";
import { EditFilled } from "@ant-design/icons";
import config from "../../config/config";

const dataSource = {
  key: "1",
  name: "Mike",
  email: "odunilade@gmail.com",
  date: "2nd aug, 2022",
  status: "active",
};

function Customer() {
  const [customerData, setCustomerData] = useState([]);

  const [user_id, setUser_id] = useState();
  const [formData1, setFormData1] = useState({
    model_type: "Customers",
  });
  const [progress, setProgress] = useState(false);
  const [progressLength, setProgressLength] = useState(0);
  const [editing, setEditing] = useState(null);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    SuspendRestuarant();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [formdata, setFormdata] = useState({
    entityID: editing?._id,
    model_type: "Customers",
    duration: "24hrs",
    note: "",
  });
  const [value1, setValue1] = useState("24hrs");
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setFormdata({ ...formdata, duration: e.target.value });
  };
  console.log(formdata);
  const onClose = (e) => {
    console.log(e, "I was closed.");
  };
  const [susLoading, setSusLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const SelectedCustomer = async () => {
    try {
      const res = (
        await config.get(
          `admin/customer-management/getCustomerDetails/${user_id}`
        )
      ).data;
      console.log("sel", res);
      setEditing(res.data);
    } catch (error) {
      console.log("Error", error);
    }
  };
  const Customers = async () => {
    try {
      const res = (
        await config.get(
          `admin/customer-management/list?page=1&limit=1000000000`
        )
      ).data;

      setCustomerData(res?.data);
      console.log(res.data);
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    SelectedCustomer();
  }, [user_id]);
  const SuspendRestuarant = async () => {
    setProgress(true);
    setProgressLength(0);
    setFormdata({ ...formdata, entityID: user_id });
    try {
      setProgressLength(75);
      const res = (
        await config.post(`admin/suspension/suspend`, {
          ...formdata,
          entityID: user_id,
        })
      ).data;
      SelectedCustomer();
      setProgressLength(100);
      console.log("suspend", res);
    } catch (error) {
      console.log("suspend", error);
      setError(error?.response?.data?.message);
    }
    setProgressLength(100);
    setIsModalOpen(false);
    setProgress(false);
  };
  const confirm = (e) => {
    console.log(e);

    UnSuspendRestuarant();
  };
  const cancel = (e) => {
    console.log(e);
  };

  const UnSuspendRestuarant = async () => {
    setFormData1({ ...formData1, model_type: "Customers" });
    setSusLoading(true);

    try {
      const res = (
        await config.post(`/admin/suspension/${user_id}/unsuspend`, formData1)
      ).data;
      SelectedCustomer();
      console.log("unsuspend", res);
      message.success("Unsuspended");
      setSusLoading(false);
    } catch (error) {
      console.log("suspend", error);
      setError(error?.response?.data?.message);
      message.error(error);
    }
  };
  const [dataSource, setDataSource] = useState([...customerData]);
  const [value, setValue] = useState("");
  console.log("gg", editing);
  useEffect(() => {
    Customers();
  }, [value === ""]);
  const columns = [
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Date Joined",
      dataIndex: "createdAt",
      key: "createdAt",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, param) => {
        return (
          <Controls>
            <button
              onClick={() => {
                setUser_id(param._id);
              }}
            >
              <EditFilled />
            </button>
          </Controls>
        );
      },
    },
  ];
  return (
    <div>
      <Header
        title="Customers"
        description="Added, edit and delete customer accounts"
        button={{
          text: "+ Add customer",
          href: "/customer/add",
        }}
      />
      <Input
        placeholder="Search "
        size="middle"
        style={{ marginBottom: 20, height: 50, borderRadius: 15 }}
        value={value}
        onChange={(e) => {
          const currValue = e.target.value;
          setValue(currValue);
          const filteredData = customerData.filter((entry) =>
            entry.name.includes(currValue)
          );
          setCustomerData(filteredData);
        }}
      />
      <MyTable
        columns={columns}
        className="myTable"
        dataSource={[...customerData]}
      />
      {error && (
        <Alert message={error} type="warning" closable onClose={onClose} />
      )}
      <Modal
        title="Suspension Duration"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value="24hrs">24hrs</Radio>
              <Radio value="1m">1minite</Radio>
              <Radio value="1w">1 week</Radio>
              <Radio value="permanent">permanent</Radio>
            </Space>
          </Radio.Group>
          {progress && (
            <Progress
              style={{ marginRight: "20px" }}
              type="circle"
              percent={progressLength}
              format={() => "Suspend"}
            />
          )}
        </div>
        <TextArea
          placeholder="Note"
          required
          style={{ background: "#242424", marginTop: 15 }}
          value={formdata.note}
          onChange={(e) => {
            setFormdata({ ...formdata, note: e.target.value });
          }}
        ></TextArea>
      </Modal>

      <CustomModal
        // centered
        onCancel={() => setEditing(null)}
        visible={Boolean(editing)}
        width={800}
        footer={null}
      >
        <ModalTitle>Customer Details</ModalTitle>

        <ModalContent>
          <DetailsCon>
            <span className="label">Name</span>
            <span className="value">{editing?.name}</span>
          </DetailsCon>

          <DetailsCon>
            <span className="label">Email</span>
            <span className="value">{editing?.email}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Phone number</span>
            <span className="value">{editing?.phoneNumber}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Handle</span>
            <span className="value">{editing?.handle}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Gender</span>
            <span className="value">{editing?.gender}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Address</span>
            <span className="value">{editing?.deliveryAddress}</span>
          </DetailsCon>

          <DetailsCon>
            <span className="label">Date Of Birth</span>
            <span className="value"> {editing?.dob}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Status</span>
            <span className="value">
              {" "}
              {editing?.active ? "Active" : "Not Active"}
            </span>
          </DetailsCon>
        </ModalContent>

        <ModalContent>
          <label style={{ display: "block", color: "#fff8", marginBottom: 10 }}>
            Message
          </label>
          <TextArea />

          {editing?.deactivationStatus === "none" ? (
            <Button
              style={{
                background: "#fff",
                color: "#242424",
                marginTop: 2,
                height: 40,
              }}
              type="primary"
              block
              onClick={showModal}
            >
              Suspend
            </Button>
          ) : (
            <Popconfirm
              title="Are you sure to unsuspend this user?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a href="#" style={{ color: "#fff" }}>
                <Button
                  style={{
                    background: "#242424",
                    color: "#fff",
                    marginTop: 2,
                    height: 40,
                  }}
                  type="primary"
                  block
                  loading={susLoading}
                >
                  Unsuspend
                </Button>
              </a>
            </Popconfirm>
          )}
          <Button
            style={{ background: "#f90", color: "#fff", marginTop: 10 }}
            type="primary"
            block
          >
            Deactivate
          </Button>
        </ModalContent>
      </CustomModal>
    </div>
  );
}

export default Customer;

const MyTable = styled(Table)`
  & .ant-table {
    background: #fff1;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 40px #0006;
  }

  & .ant-table-thead {
    background: #fff4;
  }
  & .ant-table-cell {
    color: #fff;
    background: none;
    border-bottom: 1px solid #fff2;
  }
  & .ant-table-tbody > tr.ant-table-row:hover > td,
  & .ant-table-tbody > tr > td.ant-table-cell-row-hove {
    background: #fff3;
  }

  & .ant-pagination-item-link {
    background: var(--tertiary);
    color: #fff;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  & button {
    background: none;
    color: #fff;
    font-size: 16px;
  }
`;

const CustomModal = styled(Modal)`
  & .ant-modal-content {
    background: var(--tertiary);
    border-radius: 20px;
    box-shadow: 0 0 40px #0006;
  }
`;

const ModalTitle = styled.h6`
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px;
`;

const ModalContent = styled.div`
  background: #fff2;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
`;
const DetailsCon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  & span {
    display: flex;
    flex: 1;
    font-size: 16px;
  }
  & .label {
    color: #fff3;
  }
  & .value {
    color: #fffa;
  }
`;

const TextArea = styled.textarea`
  border: 1px solid #fff4;
  border-radius: 10px;
  padding: 20px;
  background: none;
  display: block;
  width: 100%;
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
`;

const SuspendBtn = styled.button`
  padding: 10px 20px;
  background: var(--secondary);
  color: #fff;
  border-radius: 10px;
`;
