import { Alert, Button, Checkbox, Col, Modal, Row, Typography } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Header from "../../component/Header";
import config from "../../config/config";
import countryCodes from "../../utils/countryCodes";

const permissions = [
  "manage_customers",
  "manage_Businesss",
  "manage_riders",
  "manage_rider_managers",
];

function AddAdminManager() {
  const router = useNavigate();

  const [error, seterror] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    router("/sub-admins");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    dialCode: "",
    phoneNumber: "",
    permissions: [],
    password: "",
  });
  console.log(formData);

  const Register = async () => {
    setLoading(true);
    try {
      const res = (
        await config.post(`admin/admin-management/register`, formData)
      ).data;
      console.log("register", res);
      showModal();
    } catch (error) {
      console.log("error", error);
      seterror(error);
    }
    setLoading(false);
  };
  const onClose = (e) => {
    console.log(e, "I was closed.");
  };
  return (
    <div>
      <Header title="Create Sub-Admin" description="Added some decscription" />
      {error && (
        <Alert
          message={error?.response?.data?.message}
          type="warning"
          closable
          onClose={onClose}
        />
      )}
      <Modal
        title="Success"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Typography>Sub Admin Suscessfully Created ...</Typography>
      </Modal>
      <div>
        <FormGroup>
          <Label>First Name</Label>
          <Input
            placeholder="eg: John"
            value={formData.firstName}
            onChange={(e) => {
              setFormData({ ...formData, firstName: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Last Name</Label>
          <Input
            placeholder="eg: Doe"
            value={formData.lastName}
            onChange={(e) => {
              setFormData({ ...formData, lastName: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Email Address</Label>
          <Input
            placeholder="eg: example@ndali.com"
            type="email"
            value={formData.email}
            onChange={(e) => {
              setFormData({ ...formData, email: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <Input
            placeholder="******"
            value={formData.password}
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
            }}
          />
        </FormGroup>

        <FormGroup>
          <Row gutter={15}>
            <Col span={10}>
              <Label>Country code</Label>
              <Select
                value={formData.dialCode}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    dialCode: e.target.value,
                  });
                }}
                placeholder="Nigeria +234"
              >
                {countryCodes.map((cur) => (
                  <option
                    style={{ background: "#242424" }}
                    value={cur.dial_code}
                    key={cur.code}
                  >{`${cur.dial_code} ${cur.name}`}</option>
                ))}
              </Select>
            </Col>
            <Col span={14}>
              <Label>Phone number</Label>
              <Input
                value={formData.phoneNumber}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    phoneNumber: e.target.value,
                  });
                }}
                placeholder="eg: 8089040350"
              />
            </Col>
          </Row>
        </FormGroup>
        <Label>Permissions</Label>
        {permissions.map((cur) => (
          <FormGroup key={cur._id}>
            <CheckboxX
              onChange={(e) => {
                let s = [...formData.permissions];
                const i = formData.permissions.indexOf(cur);
                if (i > -1) s.splice(i, 1);
                else s.push(cur);

                setFormData({ ...formData, permissions: s });
              }}
              checked={formData.permissions.indexOf(cur) > -1}
            >
              {cur}
            </CheckboxX>
          </FormGroup>
        ))}

        {error && (
          <Alert
            message={error?.response?.data?.message}
            type="warning"
            closable
            onClose={onClose}
          />
        )}
        <Button
          style={{
            padding: "0px 30px",
            height: 50,
            color: "#fff",
            background: "var(--secondary)",
            borderRadius: "10px",
            fontSize: "16px",
            marginTop: "20px",
          }}
          loading={loading}
          onClick={Register}
        >
          Create admin manager
        </Button>
      </div>
    </div>
  );
}

export default AddAdminManager;

const Form = styled.form`
  max-width: 500px;
`;
const Input = styled.input`
  padding: 10px;
  display: block;
  border-radius: 10px;
  width: 100%;
  background: #fff2;
  color: #fff;

  &::placeholder {
    color: #fff5;
  }
`;
const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  margin-bottom: 5px;
  padding: 10px;
  padding-left: 10px;
  display: block;
  color: #fffa;
`;

const Select = styled.select`
  border-radius: 10px;
  width: 100%;
  padding: 12px;
  background: #fff2;
  color: #fff;

  &::placeholder {
    color: #fff5;
  }
`;

const CheckboxX = styled(Checkbox)`
  & span {
    color: #fffa;
  }
`;
