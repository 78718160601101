import {
  Avatar,
  Button,
  Input,
  Modal,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import hello from "../../assets/images/hello.jpg";
import styled from "styled-components";
import Header from "../../component/Header";
import { EditFilled } from "@ant-design/icons";
import config from "../../config/config";
import { Link } from "react-router-dom";
const dataSource = {
  key: "1",
  name: "Mike",
  email: "odunilade@gmail.com",
  date: "2nd aug, 2022",
  status: "active",
};
const array = [1, 2, 3, 4, 5, 6, 7, 8];
function RiderManager() {
  const [riderManagerData, setRiderManagerData] = useState([]);
  const [editing, setEditing] = useState(null);
  const [user_id, setUser_id] = useState();
  const [managerID, setManagerID] = useState("");
  const columns = [
    {
      title: "Company Name",
      key: "business_details",
      dataIndex: ["business_details", "name"],
    },

    {
      title: "Date Joined",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Status",
      dataIndex: "adminVerified",
      key: "adminVerified",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, param) => {
        return (
          <Controls>
            <button
              onClick={() => {
                setUser_id(param._id);
              }}
            >
              <EditFilled />
            </button>
          </Controls>
        );
      },
    },
  ];

  const RiderManager = async () => {
    try {
      const res = (
        await config.get(
          `admin/rider-management/manager/list?page=1&limit=10000000`
        )
      ).data;

      setRiderManagerData(res?.data);
      console.log(res.data, "rider");
    } catch (error) {
      console.log("Error getting rider manger", error.response);
    }
  };
  const [dataSource, setDataSource] = useState([...riderManagerData]);
  const [value, setValue] = useState("");

  useEffect(() => {
    RiderManager();
  }, [value === ""]);
  return (
    <div>
      <Header
        title="Rider Manager"
        description="Added, edit and delete RiderManager accounts"
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        {riderManagerData.map((cur, i) => (
          <Link key={i} to={`info?id=${cur._id}`}>
            <Box>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Switch
                  checked={cur.active}
                  style={{ color: "#28a745", background: "#28a745" }}
                />
              </div>
              <Avatar size={160} src={hello} />
              <div style={{ marginTop: 2, marginBottom: 2 }}>
                <Typography style={{ fontWeight: 600 }}>
                  {cur.business_details.name}
                </Typography>
                <Typography>5 : Rider</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 5,
                }}
              >
                <Button
                  type="primary"
                  style={{
                    background: "#28a745",
                    border: "#000",
                    width: "100%",
                    borderRadius: 5,
                  }}
                >
                  {cur.adminVerified ? "Approved" : "Approve"}
                </Button>
                <Button
                  type="primary"
                  style={{
                    background: "red",
                    border: "#000",
                    width: "100%",
                    borderRadius: 5,
                  }}
                >
                  Deactivate
                </Button>
              </div>
            </Box>
          </Link>
        ))}
      </div>
      {/* <Input
        placeholder="Search "
        size="middle"
        style={{ marginBottom: 20, height: 50, borderRadius: 15 }}
        value={value}
        onChange={(e) => {
          const currValue = e.target.value;
          setValue(currValue);
          const filteredData = riderManagerData.filter((entry) =>
            entry.name.includes(currValue)
          );
          setRiderManagerData(filteredData);
        }}
      />
      <MyTable
        columns={columns}
        className="myTable"
        dataSource={[...riderManagerData]}
      />

      <CustomModal
        // centered
        onCancel={() => setEditing(null)}
        visible={Boolean(editing)}
        width={800}
        footer={null}
      >
        <ModalTitle>Personal Details</ModalTitle>

        <ModalContent>
          <DetailsCon>
            <span className="label">First name</span>
            <span className="value">{editing?.owner_details?.firstName}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Last name</span>
            <span className="value">{editing?.owner_details?.lastName}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Email</span>
            <span className="value">{editing?.email}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Phone number</span>
            <span className="value">
              {editing?.owner_details?.dialCode}
              {editing?.owner_details?.phoneNumber}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Country</span>
            <span className="value">{editing?.name}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Address</span>
            <span className="value">{editing?.business_details?.address}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">City</span>
            <span className="value"> {editing?.business_details?.city}</span>
          </DetailsCon>
        </ModalContent>
        <ModalTitle>Payment details</ModalTitle>

        <ModalContent>
          <DetailsCon>
            <span className="label">Bank</span>
            <span className="value">{editing?.bank_details?.bank_name}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Account Name</span>
            <span className="value">{editing?.bank_details?.account_name}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Account Number</span>
            <span className="value">
              {editing?.bank_details?.account_number}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Paypal_Email</span>
            <span className="value">{editing?.bank_details?.paypal_email}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">recipient_code</span>
            <span className="value">
              {editing?.bank_details?.recipient_code}
            </span>
          </DetailsCon>
        </ModalContent>
        <ModalTitle>Business Details</ModalTitle>

        <ModalContent>
          <DetailsCon>
            <span className="label">Business name</span>
            <span className="value">{editing?.business_details?.name}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Business slogan</span>
            <span className="value">{editing?.business_details?.slogan}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Business Email</span>
            <span className="value">
              {editing?.business_details?.customer_care_email}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Business Type</span>
            <span className="value">{editing?.model_type}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Business address</span>
            <span className="value">{editing?.business_details?.address}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Country</span>
            <span className="value">{editing?.name}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">City</span>
            <span className="value">{editing?.business_details?.city}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Tax ID</span>
            <span className="value">{editing?.business_details?.taxID}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Phone Numbers</span>
            <span className="value">
              {editing?.business_details?.customer_care_phone?.map((cur, i) => (
                <ul key={i}>
                  <li style={{ marginRight: 20 }}>{cur}</li>
                </ul>
              ))}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Hours</span>
            <span className="value">
              {editing?.rHours?.oHour}-{editing?.rHours?.cHour}
            </span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Business Email verification</span>
            <span className="value">{editing?.email_verified.toString()}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">flatRate</span>
            <span className="value">{editing?.rate?.flatRate}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Rate Per Kilometer</span>
            <span className="value">{editing?.rate?.ratePerKilometer}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Online</span>
            <span className="value">{editing?.online?.toString()}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">Manager Id</span>
            <span className="value">{editing?.managerID}</span>
          </DetailsCon>
          <DetailsCon>
            <span className="label">AdminVerified</span>
            <span className="value">{editing?.adminVerified.toString()}</span>
          </DetailsCon>
        </ModalContent>

        <ModalTitle>Message</ModalTitle>

        <ModalContent>
          <label style={{ display: "block", color: "#fff8", marginBottom: 10 }}>
            Note
          </label>

          <TextArea />
          <Space
            direction="vertical"
            style={{
              width: "100%",
            }}
          >
            <Button
              style={{ background: "#0DAD09", color: "#fff" }}
              type="primary"
              block
            >
              Approve
            </Button>
            <Button
              style={{ background: "#fff", color: "#242424", marginTop: 10 }}
              type="primary"
              block
            >
              Suspend
            </Button>
            <Button
              style={{ background: "#f90", color: "#fff", marginTop: 10 }}
              type="primary"
              block
            >
              Deactivate
            </Button>
          </Space>
          <Button>Fleet</Button>
        </ModalContent>
      </CustomModal> */}
    </div>
  );
}

export default RiderManager;

const MyTable = styled(Table)`
  & .ant-table {
    background: #fff1;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 40px #0006;
  }

  & .ant-table-thead {
    background: #fff4;
  }
  & .ant-table-cell {
    color: #fff;
    background: none;
    border-bottom: 1px solid #fff2;
  }
  & .ant-table-tbody > tr.ant-table-row:hover > td,
  & .ant-table-tbody > tr > td.ant-table-cell-row-hove {
    background: #fff3;
  }

  & .ant-pagination-item-link {
    background: var(--tertiary);
    color: #fff;
  }
`;
const Controls = styled.div`
  display: flex;
  align-items: center;
  & button {
    background: none;
    color: #fff;
    font-size: 16px;
  }
`;

const CustomModal = styled(Modal)`
  & .ant-modal-content {
    background: var(--tertiary);
    border-radius: 20px;
    box-shadow: 0 0 40px #0006;
  }
`;

const ModalTitle = styled.h6`
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px;
`;

const Box = styled.div`
  background: #f7f7f7;
  padding: 15px;
  border-radius: 10px;
  min-width: 250px;
  min-height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
`;
const DetailsCon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  & span {
    display: flex;
    flex: 1;
    font-size: 16px;
  }
  & .label {
    color: #fff3;
  }
  & .value {
    color: #fffa;
  }
`;

const TextArea = styled.textarea`
  border: 1px solid #fff4;
  border-radius: 10px;
  padding: 20px;
  background: none;
  display: block;
  width: 100%;
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
`;
