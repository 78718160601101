import React from "react";
import styled from "styled-components";

function ChatBubble({ message, sender, date }) {
  const isMine = sender === "ndali";
  return (
    <Container style={{ marginLeft: isMine ? "auto" : 0 }}>
      <Sender>{sender}</Sender>
      <Bubble>
        <span>{message}</span>
      </Bubble>
    </Container>
  );
}

export default ChatBubble;

const Container = styled.div`
  margin-bottom: 20px;
  max-width: 60%;
`;
const Bubble = styled.div`
  background: #fff2;
  padding: 10px 20px;
  border-radius: 20px;
`;

const Sender = styled.p`
  margin-bottom: 5px;
  font-size: 13px;
  color: #fff8;
  text-transform: capitalize;
`;
