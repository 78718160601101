import config from "../../config/config";
import { AUTH_ERROR, AUTH_LOADING, AUTH_SUCCESS } from "./types";

export const loginHandler = async (formData, dispatch) => {
  dispatch({
    type: AUTH_LOADING,
  });
  try {
    const res = await config.post("admin/auth/login", formData);

    localStorage.setItem("nd-rest-tkn", res.data.data?.token);
    localStorage.setItem("userData", JSON.stringify(res.data.data));
    config.defaults.headers.post["Content-Type"] = "application/json";
    config.defaults.headers.Authorization = `Bearer ${res.data?.data?.token}`;

    dispatch({
      type: AUTH_SUCCESS,
      payload: res.data?.data,
    });

    return true;
  } catch (error) {
    console.log("Error", error.response);
    dispatch({
      type: AUTH_ERROR,
      payload: error?.response?.data?.message,
    });
    return false;
  }
};

export const getCurrentUser = async (dispatch) => {
  dispatch({
    type: AUTH_LOADING,
  });

  const token = localStorage.getItem("nd-rest-tkn");

  const user = localStorage.getItem("userData");
  config.defaults.headers.post["Content-Type"] = "application/json";
  config.defaults.headers.Authorization = `Bearer ${token}`;
  if (user)
    dispatch({
      type: AUTH_SUCCESS,
      payload: JSON.parse(user),
    });
};
