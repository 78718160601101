import { AUTH_ERROR, AUTH_LOADING, AUTH_SUCCESS } from "./types";

export default function authReducer(state, { type, payload }) {
  switch (type) {
    case AUTH_LOADING:
      return { ...state, loading: true };
    case AUTH_SUCCESS:
      return {
        error: payload || null,
        loggedIn: true,
        data: payload || {},
        loading: false,
      };
    case AUTH_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
}
