import React, { useContext, useState } from "react";
import styled from "styled-components";
import { loginHandler } from "../context/auth/action";
import { GlobalContext } from "../context/provider";
import { useNavigate } from "react-router-dom";

import { Alert, Button } from "antd";
function Auth() {
  const router = useNavigate();
  const { authDispatch, auth } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const success = await loginHandler(formData, authDispatch);
    console.log("result", success);
    
    console.log(auth.error);
    setLoading(false);
    if (success) {
      router("/");
    } else {
      setError("Invalid Login");
    }
  };
  const onClose = (e) => {
    console.log(e, "I was closed.");
  };
  return (
    <Root>
      {error && (
        <Alert
          message={error}
          description="Check Email or Password"
          type="error"
          closable
          onClose={onClose}
          style={{ marginBottom: 20 }}
        />
      )}

      <Form>
        <h3>Sign In</h3>

        <FormGroup>
          <Label>Email</Label>
          <Input
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            type="email"
            required
            placeholder="example@ndali.com"
          />
        </FormGroup>

        <FormGroup>
          <Label>Password</Label>
          <Input
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
            type="password"
            required
            placeholder="*****"
          />
        </FormGroup>

        <Button
          style={{
            background: "var(--primary)",
            color: "#fff",
            height: 40,
            width: 120,
            borderRadius: "5px",
          }}
          size="middle"
          onClick={submitHandler}
          loading={loading}
        >
          Sign In
        </Button>
      </Form>
    </Root>
  );
}

export default Auth;

const Root = styled.div`
  padding: 10vh 20px;
`;

const Form = styled.form`
  margin: 0 auto;
  border: 1px solid #fff5;
  padding: 25px;
  border-radius: 20px;

  & h3 {
    color: #fff;
    margin-bottom: 20px;
    font-size: 36px;
  }
  @media (min-width: 1200px) {
    width: 40vw;
  }
`;

const Input = styled.input`
  padding: 10px;
  padding: 10px;
  display: block;
  border-radius: 10px;
  width: 100%;
  background: #fff2;
  color: #fff;

  &::placeholder {
    color: #fff5;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin-bottom: 5px;
  display: block;
  color: #fffa;
`;
